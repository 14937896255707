@import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";

/* HEADER */

#headerDiv {
  height: 50px;
  background: #fdaa9a;
  text-align: center;
}

#homeIcon {
  width: 30px;
  filter: invert(1);
  position: absolute;
  top: 7px;
  left: 7px;
}

#plusIcon {
  width: 30px;
  filter: invert(1);
  position: absolute;
  top: 7px;
  right: 7px;
}

#skylineIcon {
  width: 90px;
  margin: auto;
  margin-top: -20px;
}


/* //LOGIN PAGE */

#loginImg {
  width: 100%;
}

.loginButtons {
  width: 80%;
  margin-left: 10%;
  background: #3b5998;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 15px 0;
  -webkit-appearance: none !important;
  border: 0px;
  border-radius: 30px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}


/* //LANDING PAGE */

#homeUL {
  padding: 0;
}
.homeButtons {
  width: 80%;
  margin-left: 10%;
  background: #fcaa9b;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 15px 0;
  -webkit-appearance: none !important;
  border: 0px;
  border-radius: 30px;
  text-align: center;
  margin-bottom: 20px;
}

.homeButtonsNew {
  background: #bdcdf3
}

.homeButtonsBrowse {
  background: #bdcdf3
}

.homeButtons > a {
  text-decoration: none;
  color: white;
}




/* review PAGE */

#addressLight {
  color: #c5c5c5;
}

pre {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    white-space: pre-wrap;       /* css-3 */
     white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
     white-space: -pre-wrap;      /* Opera 4-6 */
     white-space: -o-pre-wrap;    /* Opera 7 */
     word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

#loadingDiv{
  text-align: center;
  margin-top: 50%;

}

.Map {
  height: 500px;
}

.mapboxgl-control-container {
  display: none;
}

#berries {
  width: 100%;
}

body {
  margin: 0;
}

.reviewButtons {
  font-weight: 500;
}


.image-gallery-slide {
  /* helps to align portrait orientation images in center of frame */
  text-align: center;
}

#titleDiv {
  text-align: left;
  margin-left: 20px;
  margin-bottom: 0;
}

#title {
  margin-bottom: 0;
}

#address, #addressLight {
  margin-top: 0;
  margin-bottom: 2px;
}

#date {
  color: #8e8e8e;
  text-transform: capitalize;
}

#text {
  border-radius: 15px;
  padding: 15px;
  font-weight: 500;
  margin: 20px;
}

#text:nth-child(odd) {
  background: #dfe8fd;
}

#text:nth-child(even) {
  background: #dcedfb;
}

.reviewChoices {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin: 20px;
  margin-bottom: 0;
}

/* BROWSE */

.legend {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 20px;
}

.box {
  background-color: #c3c3c3;
  color: #585858;
  border-radius: 30px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}

.boxLower {
  text-align: center;
  margin-top: 4px;
}

.span {
  background: #c5c5c5;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  padding: 5px 15px 10px 16px;
  font-size: 30px;
  font-weight: 700;
  color: white;
}

ul {
  list-style-type: none;
  text-decoration: none;
  color: black;
}

.browseLI {
  background: #dfe8fd;
  padding: 15px;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  border-radius: 30px;
  margin: 20px;
}

.browseUL {
  padding: 0;
}

.browseLI > a {
  text-decoration: none;
  color: #000000;
  font-weight: 500;
}

.browseUL > a {
  background: #dfe8fd;
  padding: 15px;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  border-radius: 30px;
  margin: 20px;
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  display: block;
}

.citySelect {
  padding: 15px;
  border-radius: 30px;
  text-decoration: none;
  color: #585858;
  font-weight: 500;
  display: block;
  border: none;
  font-size: 1rem;
  width: 60%;
  margin: auto;
  background: #c3c3c3;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}

option {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

#beerBox {
  background: #f1e6ad;
}

/* ///NEW REVIEW FORM */

.newSubmit {
  margin-top: 20px;
}

.none {
  display: none;
}

.formLabel {
  color: #969696;
  margin-left: 5%;
  font-size: .9rem;
}

.formInput {
  color: black;
  font-size: 1rem;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  border: none;
  margin-bottom: 0;
  border-radius: 0;
  padding: 0;
  -webkit-appearance: none !important;
}

.formTextarea {
  color: black;
  font-size: 1rem;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  border: none;
  margin-bottom: 0;
  border-radius: 0;
  padding: 0;
  height: 200px;
  resize: none;
  -webkit-appearance: none !important;
}

.formTextareaBeer {
  color: black;
  font-size: 1rem;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  border: none;
  margin-bottom: 0;
  border-radius: 0;
  padding: 0;
  height: 80px;
  resize: none;
  -webkit-appearance: none !important;
}

.formSelect {
  color: black;
  font-size: 1rem;
  width: 90%;
  margin-left: 5%;
  border: none;
  margin-bottom: 0;
  background: none;
  border-radius: 0;
  padding: 0;
  -webkit-appearance: none !important;
}

#submit {
  width: 80%;
  margin-left: 10%;
  background: #bdcdf3;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  height: 50px;
  -webkit-appearance: none !important;
  border: 0px;
  border-radius: 30px;
  margin-bottom: 30px;
}

.hr {
  width: 90%;
  margin-left: 5%;
  margin-top: 0;
}

#sliderDiv {
  text-align: center;
  margin: auto;
}

#ratingBubble {
  display: inline-block;
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 30px;
  color: white;
  text-align: center;
  font-size: 1.5em;
  font-weight: 700;
}

#ratingBubbleReview {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  color: white;
  text-align: center;
  font-size: 1em;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

#ratingDiv {
  text-align: center;
}

#ratingDivReview {
  text-align: left;
}

.form {
  margin-top: 20px;
}

#formTitle {
  margin-left: 5%;
  font-weight: 300;
}

/* ////PHOTO UPLOAD */

#photoUploadTitle {
  margin-left: 5%;
  font-weight: 300;
  text-align: left;
}

.selectPhotoUpload {
  width: 80%;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 10px 0;
  -webkit-appearance: none !important;
  border: 0px;
  border-radius: 30px;
}

.gray {
  background: #c5c5c5;
}

.green {
  background: #95ea4e
}

#submitPhotoUpload, #submitFinish {
  width: 80%;
  background: #bdcdf3;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 10px 0;
  -webkit-appearance: none !important;
  border: 0px;
  border-radius: 30px;
}

#fileInput {
  display: none;
}

#successMessage {
  color: green;
  font-weight: 400;
  font-size: 1.5rem;
}

#previewImg {
  width: 200px !important;
  margin-bottom: 1em;
}

#left {
  text-align: left;
}

.formTextareaCaption {
  color: black;
  font-size: 1rem;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  border: none;
  margin-bottom: 0;
  border-radius: 0;
  padding: 0;
  height: 50px;
  resize: none;
  -webkit-appearance: none !important;
}


/* Whisky */

@import url('https://fonts.googleapis.com/css?family=Special+Elite&display=swap');

.whiskyBody {
  background: black;
}

#whiskyLink {
  text-align: center;
  color: black;
}

#whiskyLinkA {
  color: black;
  text-decoration: none;
  font-weight: 400;
  font-family: 'Special Elite', cursive;
}

#titleWhisky {
  font-family: 'Special Elite', cursive;
  color: white;
  font-size: 1em;
}

#outerOverallWhisky
{
  background: black;
}

#headerDivWhiskey {
  height: 50px;
  text-align: center;
  background: black;
}

#textWhisky {
  border-radius: 15px;
  font-weight: 500;
  margin: 20px;
  background: black;
  color: white;
  font-family: 'Special Elite', cursive;
}

#reviewTextWhisky {
  color: white;
  font-family: 'Special Elite', cursive;
}

.spanWhisky {
  background: black;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  padding: 5px 15px 10px 16px;
  font-size: 30px;
  font-weight: 700;
  color: white;
}

.reviewButtonsWhisky {
  font-weight: 500;
  color: white;
  font-family: 'Special Elite', cursive;
}


/* _Extras_ */
/* Jenis */
@import url('https://fonts.googleapis.com/css?family=PT+Sans&display=swap');

.flavorText {
  font-family: 'PT Sans', sans-serif;
  color: #8e8e8e;
}
/* Salt and Straw */
@import url('https://fonts.googleapis.com/css?family=Arvo&display=swap');

.saltAndStraw {
  font-family: 'Arvo', serif;
  color: #8e8e8e;
}
