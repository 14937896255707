/* //Extra components */
/* Scoreboard */
@font-face {
  font-family: Ozone;
  src: url(../../../fonts/Ozone.ttf) format('truetype');
}

.scoreboard {
  font-family: 'Ozone'
}

#pinballImg {
  float: left;
}

#topTitle {
  border: 1px solid black;
}

.oneScore {
  text-align: center;
  background: #353535;
  color: #ffda95;
  font-family: 'Ozone';
  width: 250px;
  font-size: 2rem;
  margin: auto;
  margin-top: 10px;
  border-radius: 5px;
}

.oneScoreTitle {
  text-align: center;
  background: #353535;
  color: #fbedd3;
  font-family: 'Ozone';
  width: 250px;
  font-size: 2rem;
  margin: auto;
  margin-top: 10px;
  border-radius: 5px;
  border: none;
  padding: 0;
}

.scoreInfo {
  margin-top: 0;
  margin-bottom: 0;
}

#plus {
  margin: auto;
  font-size: 1.5rem;
  background: #353535;
  margin-top: 10px;
  color: #fbedd3;
  width: 80px;
  border-radius: 5px;
}

#highScores {
  text-align: center;
}

.oneScoreTitleNew {
  text-align: center;
  background: #353535;
  color: #fbedd3;
  font-family: 'Ozone';
  width: 100px;
  font-size: 2rem;
  margin: auto;
  margin-top: 10px;
  border-radius: 5px;
}

.oneScoreTitleNew2 {
  text-align: center;
  background: #353535;
  color: #fbedd3;
  font-family: 'Ozone';
  width: auto;
  font-size: 2rem;
  margin: auto;
  margin-top: 10px;
  border-radius: 5px;
  border: none;
  -webkit-appearance: none !important;
  appearance: none !important;
  -moz-appearance: none !important;
  padding: 0px 10px;
}

#addScoreForm {
  text-align: center;
}

.displayNone {
  display: none;
}
